import React from 'react'
import './Footer.css'
import { Col, Row, Container } from "react-bootstrap";
import lang_data from './lang.json'
import logo from './logo_light_small.png'

import phone_icon from "../icons/phone-solid-white.svg";
import email_icon from "../icons/at-solid-white.svg";
import location_icon from "../icons/location-dot-solid-white.svg";

import company_data from "../company_data.json"

function Footer(props) {
    let lang = lang_data[props.language];
    return (
        <footer>
            <div className="content">
                <Container>
                    <Row>
                        <Col md>
                            <img className="footerLogo" src={logo} alt="CivisSeed logo" />
                        </Col>
                        <Col md>
                            <h3>{lang.useful_links}</h3>
                            <a href="http://www.vszt.hu/">Vetőmag Szövetség</a>
                            <a href="https://portal.nebih.gov.hu/">NÉBIH</a>
                            <a href="https://www.kormanyhivatal.hu/hu/szakigazgatasi-szervek/noveny-es-talajvedelmi-igazgatosag">Növény- és Talajvédelmi Igazgatóság</a>
                            <a href="https://ekaer.nav.gov.hu/">EKÁER</a>
                        </Col>
                        <Col md>
                            <h3>{lang.contact_title}</h3>
                            <a href={"tel:" + company_data.phone}><img alt="phone-icon" src={phone_icon} />{company_data.phone}</a>
                            <a href="https://goo.gl/maps/9dJmqCHuAPxB9C8q8"><img alt="location-icon" src={location_icon} />{company_data.location}</a>
                            <a href={"mailto:" + company_data.email}><img alt="email-icon" src={email_icon} />{company_data.email}</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <div className='center'>Céget nyilvántató bíróság:<br />Hajdú-Bihar Vármegyei Bíróság</div>
                            <div className='center'>Cégjegyzékszám:<br />09-09-003485</div>
                        </Col>
                        <Col md></Col>
                        <Col md></Col>
                    </Row>
                </Container>
                <div className="copyright">
                    <div>{lang.copyright}</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
