import React, { useState } from "react";
import "./About.css";
import { Col, Row, Container } from "react-bootstrap";
import kerti from "./kerti_b.png";
import fagyasztott from "./fagyasztott_b.png";
import takarmany from "./takarmany_b.png";
import lang_data from "./lang.json";

function About(props) {

  let [mobile, setDeviceChange] = useState( window.innerWidth < 768 );
  
  const changeDevice = () =>{
        if(window.innerWidth < 768){
            setDeviceChange(true);
        }
        else{
            setDeviceChange(false);
        }
     };

  window.addEventListener('resize', changeDevice);



  let lang = lang_data[props.language];

  return (
    <div id="about" className="padding-100-topbottom">
      <div className="content">
        <h1>{lang.title}</h1>
        <p>{lang.paragraph1}</p>
        <p>{lang.paragraph2}</p>
        <h3>{lang.cards_title}</h3>
        {mobile ? (
          <MobileCards details={lang.details} card_text={lang.cards} />
        ) : (
          <DesktopCards details={lang.details} card_text={lang.cards} />
        )}
      </div>
    </div>
  );
}
function MobileCards(props) {
  return (
    <div>
        <a href="/products">
            <div className="mobileCard" style={{ backgroundImage:`url("${kerti}")` }}>
                <div>
                    <span>{props.card_text[0]}</span>
                </div>
            </div>
        </a>
        <a href="/products">
            <div className="mobileCard" style={{ backgroundImage:`url("${fagyasztott}")` }}>
                <div>
                    <span>{props.card_text[1]}</span>
                </div>
            </div>
        </a>
        <a href="/products">
            <div className="mobileCard" style={{ backgroundImage:`url("${takarmany}")` }}>
                <div>
                    <span>{props.card_text[2]}</span>
                </div>
            </div>
        </a>
    </div>
  );
}
function DesktopCards(props) {
  return (
    <Container>
      <Row>
        <Col md>
          <Card 
            details={props.details} 
            text={props.card_text[0]} 
            img={kerti} />
        </Col>
        <Col md>
          <Card
            details={props.details}
            text={props.card_text[1]}
            img={fagyasztott}
          />
        </Col>
        <Col md>
          <Card
            details={props.details}
            text={props.card_text[2]}
            img={takarmany}
          />
        </Col>
      </Row>
    </Container>
  );
}

function Card(props) {
  return (
    <div className="about_card">
      <div
        className="card-img"
        style={{ backgroundImage: `url(${props.img})` }}
      ></div>
      <div className="card-text">{props.text}</div>
      <a className="about_button" href="/products">{props.details}</a>
    </div>
  );
}
export default About;
