import React, {useState} from 'react'
import './Menu.css'
import './Hamburger.css'
import logo from './logo_light_small.png'
import eng_img from './eng.png'
import hu_img from './hu.png'
import lang_data from './lang.json'

function Menu(props) {
    let [mobile, setDeviceChange] = useState( window.innerWidth < 768 );
  
    const changeDevice = () =>{
          if(window.innerWidth < 768){
              setDeviceChange(true);
          }
          else{
              setDeviceChange(false);
          }
       };
       window.addEventListener('resize', changeDevice);

       const [colorChange, setColorchange] = useState(false);
       const changeNavbarColor = () =>{
           if(window.scrollY >= 80){
             setColorchange(true);
           }
           else{
             setColorchange(false);
           }
        };
       window.addEventListener('scroll', changeNavbarColor);

    return (
        <div>
        { mobile ?
        <MobileMenu data = {props} bgcolor={colorChange}/>
        :<DesktopMenu data = {props} bgcolor={colorChange}/>
        }
        </div>
    )
}

function MobileMenu(props){
    let bgcolor = props.bgcolor;
    props = props.data
    let [openMobileNavbar, switchMobileNavbar] = useState(false)
    let setLanguage = (l) =>{
        props.onChange(l)
    }
    //const [xhamburger, xHamburgerSwitch] = useState(false)
    return(
        <div id="mobile-menu" className={bgcolor && !openMobileNavbar ? "darkbg":""}>
            <a href="/"><img id="logo" src={logo} alt="CivisSeed logo"/></a>
            <HamburgerIcon xhamburger={openMobileNavbar} onChange={switchMobileNavbar}/>

            <div className={ openMobileNavbar ?'mobile-side-nav side-nav-open':'mobile-side-nav'}>
                <div className="mobile-side-buttonholder">
                    <div>
                <a href="/#about"
                onClick={()=>{switchMobileNavbar(false)}}
                >{lang_data[props.language].about}</a>
                <a href="/products"
                onClick={()=>{switchMobileNavbar(false)}}
                >{lang_data[props.language].products}</a>
                <a href="/contact"
                onClick={()=>{switchMobileNavbar(false)}}
                >{lang_data[props.language].contact}</a>
                     {
                        props.language === "hu" ?
                        <button onClick={() => {setLanguage("eng")}}><img alt="english" src={eng_img}/></button>
                        : <button onClick={() => setLanguage("hu")}><img alt="hungarian" src={hu_img}/></button>
                    }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

function HamburgerIcon(props){
    return (
        <div onClick={() => {
            props.onChange(!props.xhamburger)
        }} className={props.xhamburger ? 'hamburger x-hamburger' : 'hamburger'}>
            <div></div>
        </div>
    )
}

function DesktopMenu(props){
    let bgcolor = props.bgcolor;
    props = props.data
    let setLanguage = (l) =>{
        props.onChange(l)
    }

    return (
        <div id="menu" className={bgcolor? 'darkbg' : ''}>
            <div className="content menu_layout">
                <a href="/"><img id="logo" src={logo}/></a>
                <div id="menubuttons">
                    <a href="/#about">{lang_data[props.language].about}</a>|
                    <a href="/products">{lang_data[props.language].products}</a>|
                    <a href="/contact">{lang_data[props.language].contact}</a>|
                    {
                        props.language === "hu" ?
                        <button onClick={() => setLanguage("eng")}><img alt="english" src={eng_img}/></button>
                        : <button onClick={() => setLanguage("hu")}><img alt="hungarian" src={hu_img}/></button>
                    }
                </div>
            </div>
        </div>
    )
}

export default Menu
