import React, {useRef, useState} from "react";
import "./Contact.css";
import Footer from "../Footer/Footer";
import { Col, Row, Container } from "react-bootstrap";

import lang_data from "./lang.json";

import phone_icon from "../icons/phone-solid.svg";
import email_icon from "../icons/at-solid.svg";
import location_icon from "../icons/location-dot-solid.svg";

import company_data from "../company_data.json"

import emailjs from '@emailjs/browser'



function Contact(props) {
  let lang = lang_data[props.language];
  const form = useRef();

  let [sendMsg, setSendMsg] = useState({"message":"", "color":""});

  //TODO https://www.emailjs.com/docs/sdk/send/
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_serviceID,
      process.env.REACT_APP_templateID,
      form.current,
      process.env.REACT_APP_userID
      )
      .then((result) => {
        console.log(result.text);
        setSendMsg({"message":"Üzenet sikeresen elküldve! Köszönjük!","color":"green"})
        document.getElementById("messageForm").reset();

      }, (error) => {
          console.log(error.text);
          setSendMsg({"message":"Hiba történt az üzenet elküldése közben!", "color":"red"})
      });
  }


  return (
    <div>
      <div id="menu-bg"></div>
      <div id="contact-holder">
        <Container>
          <Row>
            <h1>{lang.title}</h1>
          </Row>
          <Row>
            <Col md={5} className="input-holder">
              <form ref={form} onSubmit={sendEmail} id="messageForm">
                <label htmlFor="name-input">{lang.name}</label>
                <input type="text" name="sender" id="name-input" />

                <label htmlFor="email-input">{lang.email}</label>
                <input type="email" name="email" id="email-input" />

                <label htmlFor="message-input">{lang.message}</label>
                <textarea id="message-input" name="message"></textarea>

                <p style={{color:sendMsg.color}}>{sendMsg.message}</p>
                <input type="submit" value={lang.send} className="about_button"/>
              </form>
            </Col>
            <Col md={1}></Col>
            <Col md={6}>
              <iframe
              title="civisseed_google_location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d899.2490888737029!2d21.613886459990656!3d47.51518450514868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47470e405d373783%3A0xf9f8ee23f67ec95c!2sCivis%20Vet%C5%91mag%20Kft.!5e0!3m2!1shu!2shu!4v1645089161383!5m2!1shu!2shu"
                className="google-map"
                loading="lazy"
              ></iframe>
              <table className="classic-contact">
                <tbody>
                <tr>
                  <td>
                    <img alt="icon-phone" src={phone_icon} />
                  </td>
                  <td>
                    <a href={"tel:"+company_data.phone}>{company_data.phone}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img alt="icon-location" src={location_icon} />
                  </td>
                  <td>
                    <a href="https://goo.gl/maps/9dJmqCHuAPxB9C8q8">{company_data.location}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img alt="icon-email" src={email_icon} />
                  </td>
                  <td>
                    <a href={"mailto:"+company_data.email}>{company_data.email}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer language={props.language} />
    </div>
  );
}

export default Contact;
