import React from 'react'
import './Hero.css'
import arrow from './angle-down-solid.svg'
import lang_data from "./lang.json";

function Hero(props) {
    let lang = lang_data[props.language];
    return (
        <div id="hero">
            <div id="hero_title">
                <h1>{lang.header1}</h1>
                <h2>{lang.header2}</h2>
                <a href="/products">{lang.button}</a>
            </div>

            <a href="#about" id="arrow_down">
                <img src={arrow}/>
            </a>

        </div>
    )
}

export default Hero
