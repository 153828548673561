import React from 'react'
import './ImageBgSection.css'
import { Col, Row, Container } from "react-bootstrap";

import bg1 from './bg1.png'
import bg1_pics from './pea.png'
import bg1_text from './lang1.json'

import bg2_text from './lang2.json'
import map from './megyek3.png'

import bg3_text from './lang3.json'
import bg3 from './bg3.jpg'

import bg4_text from './lang4.json'

import dry from './dry.png'
import pea_hand from './pea_hand.png'
import powder from './powder.png'


function ImageBgSection(props) {
    return (
        <div>
            {props.type===1 ?
            <ImageBgSection1 language={props.language} />
            : null}
            {props.type===2 ?
            <ImageBgSection2 language={props.language} />
            : null}
            {props.type===3 ?
            <ImageBgSection3 language={props.language} />
            : null}
            {props.type===4 ?
            <ImageBgSection4 language={props.language} />
            : null}
        </div>
    )
}


function ImageBgSection1(props) {
    let lang = bg1_text[props.language];
    return (
        <div className="bg_section padding-100-topbottom" style={{ backgroundImage:`url("${bg1}")` }}>
            <Container className="wider">
                <Row>
                    <Col lg={4} className="extra_picture_holder">
                        <img className="extra_picture hide_on_phone" src={bg1_pics} alt="pea"/>
                    </Col>
                    <Col lg={8} className="dark_bg">
                        <h3>{lang.subtitle}</h3>
                        <p>{lang.paragraph1}</p>
                        <p>{lang.paragraph2}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

function ImageBgSection2(props) {
    let lang = bg2_text[props.language];
    return (
        <div className="bg_section padding-100-topbottom" style={{ backgroundColor:`white` }}>
            <Container className="wider">
                <h1>{lang.title}</h1>
            </Container>
            <Container className="wider">
                <Row>
                    <Col lg={6} style={{ marginBottom:50 }}>
                        <p>{lang.paragraph1}</p>
                        <p>{lang.paragraph2}</p>
                        <a className="about_button" href="/products">{lang.button}</a>
                    </Col>
                    <Col lg={6} className="extra_picture_holder">
                        <img className="extra_picture" src={map} alt="map" style={{ marginBottom:50 }}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

function ImageBgSection3(props) {
    let lang = bg3_text[props.language];
    return (
        <div className="bg_section background_hide" style={{ backgroundImage:`url("${bg3}")` }}>
            <div className="bg_color_filter padding-100-topbottom">
            <Container className="wider">
                <Row>
                    <Col lg={6}>
                    </Col>
                    <Col lg={6} className="dark_bg">
                        <h3>{lang.subtitle}</h3>
                        <p>{lang.paragraph1}</p>
                        <p>{lang.paragraph2}</p>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    )
}

function ImageBgSection4(props){
    let lang = bg4_text[props.language];
    let list1 = [];
    const list2 = [];

    for(let i=0; i<lang.list1.items.length; i++){
        let item = lang.list1.items[i]
        list1.push(<li key={item.text}>
            <a href="/products">{item.text}</a>
        </li>)
    }

    for(let i=0; i<lang.list2.items.length; i++){
        let item = lang.list2.items[i]
        list2.push(<li key={item.text}>
            <a href="/products">{item.text}</a>
        </li>)
    }

    return (
        <div className="bg_section padding-100-topbottom" style={{ backgroundColor:`white` }}>
            <Container className="wider">
                <Row>
                <h1>{lang.title}</h1>
                <p>{lang.paragraph1}</p>
                </Row>
            </Container>
            <Container className="wider">
                <Row>
                    <Col md={6}>

                        <h3>{lang.list1.title}</h3>
                        <ul>
                        {list1}
                        </ul>
                        <h3>{lang.list2.title}</h3>
                        <ul>
                        {list2}
                        </ul>
                    </Col>
                    <Col md={6} className="hide-on-small">
                        <div className="layered_image"
                        style={{ backgroundImage:`url("${dry}")` }}></div>
                        <div className="layered_image"
                        style={{ backgroundImage:`url("${pea_hand}")` }}></div>
                        <div className="layered_image"
                        style={{ backgroundImage:`url("${powder}")` }}></div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


// import dry from './dry.png'
// import pea_hand from './pea_hand.png'
// import powder from './powder.png'

export default ImageBgSection
