import React, {useState, useEffect} from 'react'
import axios from '../axios'
import './Products.css'
import './Peacard.css'
import Footer from '../Footer/Footer'
import { Col, Row, Container } from "react-bootstrap";
import lang_data from './lang.json'


function Products(props) {
    let [products, setProducts] = useState({});
    let [filter] = useState('/all')

    let language = props.language
    
    const fetchData = async () => {
        axios.get(filter, {})
        .then(res => {
            const data = res.data.data
            setProducts(data)
        }).catch((error) => {alert("Hiba a termékek betöltése során!");console.log("Error msg:" + error)})
      };

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <div>
            <div id="menu-bg"></div>
            <div id="products">
                <Container>
                    <Row>
                        <Col md={3}>
                            <Filters language={language} />
                        </Col>
                        <Col md={9}>
                            <PeaCards language={language} peaData = {products}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer language={language}/>
        </div>
    )
}

function Filters(props){
    let lang = lang_data[props.language]
    return(
        <div className="filters">
            <input type="checkbox" name="all" id="all"/>
            <label htmlFor="all">{lang.all}</label><br/>
            <hr/>
            <h2>{lang.type_text}</h2>
            <input type="checkbox" name="all" id="type1"/>
            <label htmlFor="type1">{lang.garden}</label><br/>
            <input type="checkbox" name="all" id="type2"/>
            <label htmlFor="type2">{lang.industrial}</label><br/>
            <input type="checkbox" name="all" id="type3"/>
            <label htmlFor="type3">{lang.feed}</label><br/>
            <hr/>
            <h2>{lang.ripening_group}</h2>
            <input type="checkbox" name="all" id="A2"/>
            <label htmlFor="A2">{lang.early}</label><br/>
            <input type="checkbox" name="all" id="B1"/>
            <label htmlFor="B1">{lang.middle_early}</label><br/>
            <input type="checkbox" name="all" id="C1"/>
            <label htmlFor="C1">{lang.late}</label><br/>
        </div>
    )
}

function PeaCards(props){
    let peas = [];
    let lang = lang_data[props.language]
    for (let i = 0; i < props.peaData.length; i++) {
        peas.push(
        <Col key={props.peaData[i].id} sm={6} md={4}>
            <PeaCard language={props.language} pea={props.peaData[i]}/>
        </Col>)
    }

    return(
        <Container className="cards_holder">
            <Row>
            <h2>{lang.garden}</h2>
            <hr/>
            {peas}
            </Row>
        </Container>
    )
}

function PeaCard(props){
    let pea = props.pea;

    return(
        <div className="peacard">
            <div className="peacard_image">
    <div>{pea.ripening_group}</div>
            </div>
            
            <h3>{pea.name}</h3>
            <PeaDetails language={props.language} pea={pea}/>
        </div>
    )
}

function PeaDetails(props){
    const [isOpen, setIsOpen] = useState(false);
    console.log(`${props.language}_description`)
    return (
        <div>
        <button onClick={() => {setIsOpen(true)}}>
            {lang_data[props.language].details}
        </button>
        { 
        isOpen ?
        <div className="peaPopup" onClick={() => {setIsOpen(false)}}>
            <div>
                <Container>
                    <Row > 
                        <Col sm={6}>Kép</Col>
                        <Col sm={6}>
                            <h2>{props.pea.name}</h2>
                            <p>{props.pea[`${props.language}_description`]}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        : null
        }
        </div>
    )
}

export default Products
