import React, {useState} from 'react'

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom' 

import Menu from './Menu/Menu'
import Hero from './Hero/Hero'
import About from './About/About'
import ImageBgSection from './ImageBgSection/ImageBgSection'
import Footer from './Footer/Footer'
import Products from './Products/Products'
import Contact from './Contact/Contact'

function App() {
    let [language, changeLanguage] = useState(()=>{return "hu"});

    let setLanguage = (value)=>{
        changeLanguage(value)
    }
    return(
        <Router>
        <Menu language={language} onChange={setLanguage}/>
        <Switch>
            <Route exact path="/">
               <MainPage language={language}/>
            </Route>
            <Route exact path="/contact">
               <Contact language={language}/>
            </Route>
            <Route path="/products">
                <Products language={language}/>
            </Route>
        </Switch>

    </Router>
    )
}

function MainPage(props){
    let language = props.language
    return (
        <div className="main">
            <Hero language={language}/>
            <About language={language}/>
            <ImageBgSection  language={language} type={1}/>
            <ImageBgSection  language={language} type={2}/>
            <ImageBgSection  language={language} type={3}/>
            <ImageBgSection  language={language} type={4}/>


            <Footer language={language}/>
        </div>
    )
}

export default App
